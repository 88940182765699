import * as React from 'react';

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    imageRendering="optimizeQuality"
    shapeRendering="geometricPrecision"
    style={{
      enableBackground: 'new 0 0 512 512',
    }}
    textRendering="geometricPrecision"
    viewBox="0 0 512 512"
    {...props}
  >
    <path
      d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256 256-114.6 256-256S397.4 0 256 0zm-64.3 339.8c-46 0-83.2-37.5-83.2-83.8s37.3-83.8 83.2-83.8c45.9 0 83.2 37.5 83.2 83.8s-37.3 83.8-83.2 83.8zm132.9-4.9c-23 0-41.6-35.3-41.6-78.9 0-43.5 18.6-78.9 41.6-78.9s41.6 35.3 41.6 78.9c0 43.6-18.7 78.9-41.6 78.9zm64.3-8.2c-8.1 0-14.6-31.6-14.6-70.7 0-39 6.6-70.7 14.6-70.7 8.1 0 14.6 31.6 14.6 70.7 0 39-6.5 70.7-14.6 70.7z"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        fill: '#fff',
      }}
    />
  </svg>
);
export default SvgComponent;
